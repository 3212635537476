<template>
  <v-card color="white">
    <crud-header
      :title="title"
      type="AnnotationSubType"
      store="annotationSubType"
    />
    <annotation-sub-type />
  </v-card>
</template>

<script type="text/babel">
import annotationSubTypeModule from "@/store/modules/annotation-sub-type";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import AnnotationSubType from "../../components/annotation-sub-type/AnnotationSubType";

const { mapGetters } = createNamespacedHelpers("annotationSubType");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "annotationSubType",
  annotationSubTypeModule
);

export default {
  name: "role-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    AnnotationSubType,
    CrudHeader
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["title", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>
