<template>
  <v-container fluid>
    <v-text-field
      v-model="title"
      v-validate="'required|max:255'"
      label="Title"
      autocomplete="not-my-title"
      :counter="255"
      :error-messages="errors.collect('title')"
      data-vv-name="title"
      required
    />
    <v-autocomplete-annotation-type v-model="type" />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteAnnotationType from "../annotation-type/VAutocompleteAnnotationType";
const { mapComputed } = createNamespacedHelpers("annotationSubType");

export default {
  name: "employee",
  components: { VAutocompleteAnnotationType },
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed(["title", "type"])
  }
};
</script>
