<template>
  <tr>
    <td>
      <v-icon :style="`color: ${annotationSubType.color}`" class="mr-3">
        {{
          annotationSubType.color ? "brightness_1" : "panorama_fish_eye"
        }} </v-icon
      >{{ annotationSubType.title }}
    </td>
    <td>
      {{ typeName }}
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'annotation-sub-types.show',
          params: { annotationSubTypeId: annotationSubType.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import { isNil } from "lodash";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapAnnotationSubTypeGetters,
  mapState: mapAnnotationSubTypeState
} = createNamespacedHelpers("annotationSubTypes");

export default {
  name: "annotation-sub-type-table-row",
  props: {
    annotationSubTypeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapAnnotationSubTypeGetters([FIND_BY_ID]),
    ...mapAnnotationSubTypeState(["annotationType"]),
    annotationSubType() {
      return this[FIND_BY_ID](this.annotationSubTypeId);
    },
    typeName() {
      return !isNil(this.annotationSubType.type)
        ? this.annotationType[this.annotationSubType.type].name
        : null;
    }
  }
};
</script>
