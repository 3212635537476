<template>
  <table-overview
    title="Annotation Sub Types"
    store="annotationSubTypes"
    :headers="[
      { text: 'Title', value: 'title' },
      { text: 'Types', value: 'types', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'annotation_sub_type_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <annotation-sub-type-table-row
      slot="table-row"
      slot-scope="{ item }"
      :annotation-sub-type-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import annotationSubTypesModule from "@/store/modules/annotation-sub-types";
import AnnotationSubTypeTableRow from "../../components/annotation-sub-type/AnnotationSubTypeTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "annotationSubTypes",
  annotationSubTypesModule
);

export default {
  name: "annotation-sub-types-view",
  components: {
    AnnotationSubTypeTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "annotation-sub-types.create" },
        rolesOrPermissions: "annotation_sub_type_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
